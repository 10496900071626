import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../utils/format-price"
import {
  productCardStyle,
  //productHeadingStyle,
  //productImageStyle,
  productDetailsStyle,
  //productFooterStyle,
  productVendorStyle,
  //productPrice,
} from "./product-card.module.css"

export function ProductCard({ product, eager }) {
  const {
    title,
    description,
    priceRangeV2,
    slug,
    images: [firstImage],
    vendor,
    storefrontImages,
    productType,
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  /* const defaultImageHeight = 320
  const defaultImageWidth = 320 */
  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: "CONSTRAINED",
        aspectRatio: 1,
        //height: "100%",

        //width: "100px",
        /* height: defaultImageHeight, */
      })
    } catch (e) {
      console.error(e)
    }
  }

  const hasImage =
    firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length

  //console.log("Product description: ", product)

  return (
    <Link
      className={productCardStyle}
      to={slug}
      aria-label={`View ${title} product page`}
    >
      <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        {hasImage ? (
          <div
            //className={` ${productImageStyle}`}
            data-name="product-image-box"
            style={{ minWidth: "200px" }}
          >
            <GatsbyImage
              alt={firstImage?.altText ?? title}
              image={firstImage?.gatsbyImageData ?? storefrontImageData}
              objectFit="cover"
              loading={eager ? "eager" : "lazy"}
              style={{
                width: "100%",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            />
          </div>
        ) : (
          <div
          //style={{ height: defaultImageHeight, width: defaultImageWidth }}
          //style={{ height: "auto", width: "auto" }}
          />
        )}
        <div className="relative ">
          <div className="py-4 px-4">
            <div className={` ${productDetailsStyle}`}>
              <h2 as="h2" className="text-darkGreenText mb-2">
                <span className="">{title}</span>
              </h2>

              <div className={` mb-2 ${productVendorStyle}`}>
                <span className="text-darkGreenText ">{productType}</span>
              </div>

              <div
                className={`${productVendorStyle}`}
                style={{ height: "100" }}
              >
                {description}
              </div>
              <div className="flex items-center justify-between leading-none mt-4">
                <div
                  className={`flex items-center no-underline font-semibold ${productVendorStyle}`}
                >
                  <span className="text-darkGreenText ">{vendor}</span>
                </div>
                <div
                  className={`flex items-center no-underline text-grey-darker hover:text-red-dark font-semibold ${productVendorStyle}`}
                >
                  <span className="text-darkGreenText ">{price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    description
    productType
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    vendor
  }
`
